import React from 'react'

import { LegalPage } from 'app/marketing'

export default function Subscription(){
  return (
    <LegalPage>
      <h3>
        REALVISION INC. SUBSCRIPTION AGREEMENT
      </h3>

      <p>
        THIS SUBSCRIPTION AGREEMENT (THE “AGREEMENT”) IS AN AGREEMENT BETWEEN USERS OF THE REALVISION INC.  SERVICES AND PLATFORM (“CUSTOMER” / “YOU” / “USER”) AND REALVISION INC. (“REALVISION” / "WE" / "US", TOGETHER WITH CUSTOMER, THE “PARTIES” AND EACH, A “PARTY”).   THIS AGREEMENT GOVERNS THE TERMS AND CONDITIONS PURSUANT TO WHICH YOU MAY ACCESS AND USE THE REALVISION PLATFORM AND SERVICES. THE AGREEMENT IS ENTERED INTO AS OF THE DATE THAT  YOU FIRST CONSENT TO THE TERMS OF THIS AGREEMENT OR ACCESS THE REALVISION PLATFORM (THE “EFFECTIVE DATE”).
      </p>
      <p>
        BY CHECKING THE CONSENT BOX, OR BY ACCESSING OR USING THE REALVISION PLATFORM OR SERVICES, CUSTOMER  ACKNOWLEDGES THAT IT HAS READ AND UNDERSTOOD THE TERMS OF THIS AGREEMENT AND, FOR GOOD AND VALUABLE CONSIDERATION, AGREES TO BE BOUND BY THE TERMS OF THE AGREEMENT.  IF YOU DO NOT ACCEPT THESE TERMS, THEN DO NOT REGISTER FOR AN ACCOUNT OR USE THE REALVISION  PLATFORM OR ANY OF ITS CONTENT OR SERVICES.  THESE TERMS MAY BE AMENDED OR UPDATED BY REALVISION FROM TIME TO TIME WITHOUT NOTICE AND THE TERMS OF THE AGREEMENT MAY HAVE CHANGED SINCE YOUR LAST VISIT TO THIS WEBSITE. IT IS YOUR RESPONSIBILITY TO REVIEW THESE TERMS FOR ANY CHANGES.  YOUR USE AFTER ANY AMENDMENTS OR UPDATES OF THESE TERMS SHALL SIGNIFY YOUR ASSENT TO AND ACCEPTANCE OF SUCH REVISED TERMS. YOU SHOULD VISIT THIS PAGE PERIODICALLY TO REVIEW THESE TERMS OF USE. SHOULD YOU NOT AGREE TO THESE TERMS OR ANY AMENDMENTS THEREOF, YOUR ONLY REMEDY IS TO DISCONTINUE USE OF THE REALVISION PLATFORM AND SERVICES IN ACCORDANCE TO THE TERMINATION CONDITIONS SET FORTH IN THIS AGREEMENT.
      </p>

      <h4>1.	 Definitions.</h4>
      <dl>
        <dt>Agreement</dt>
        <dd>has the meaning set out in the introductory paragraph of this Agreement.</dd>

        <dt>Confidential and Proprietary Information</dt>
        <dd>shall have the meaning described in section 10 of this Agreement</dd>

        <dt>Customer Data</dt>
        <dd>means any images, photographs, electronic files, addresses, listing information, or other information submitted by the Customer to Realvision in connection with the Services.</dd>

        <dt>Deliverables</dt>
        <dd>means any image, video, photograph, virtual tour, presentation or any other materials prepared or created for Customer by Realvision  through the use of the Realvision Platform in connection with the Services.</dd>

        <dt>Documentation</dt>
        <dd>means any informational materials, tutorials and guidelines, user manuals, and/or reference or training instructions provided by Realvision to the Customer to facilitate Customer use of the Services.</dd>

        <dt>Effective Date</dt>
        <dd>means the date set out in the “Agreement Details” section of the chart at the beginning of this Agreement, or in the absence thereof, the start date of the Initial Term.</dd>

        <dt>Offering</dt>
        <dd>means, collectively, the Platform and the Services.</dd>

        <dt>Personal Information</dt>
        <dd>means personally identifiable information such as a name, address, email address, credit card information, date of birth and gender.</dd>

        <dt>Platform</dt>
        <dd>means, collectively, the Website and all systems of Realvision, including Realvision’s proprietary software, and Realvision’s third-party suppliers that are used in the provision of the Services.</dd>

        <dt>Services</dt>
        <dd>means the process through which Realvision utilizes the Platform to convert image files and other Customer Data provided by the Customers, or captured and created by Realvision on behalf of the Customers, into the Deliverables, and the hosting service provided by Realvision which enables access to the Deliverables over the Internet.</dd>

        <dt>Subscription Fee</dt>
        <dd>means a periodic fee payable at the start of each Term in connection with Customer’s ongoing access to and use of the Services.</dd>

        <dt>Website</dt>
        <dd>means, collectively, the Realvision website located at http://real.vision, any subdomains thereof, and other domain which hosts content related to the Offering.</dd>

        <dt>Term</dt>
        <dd>means the term of this Agreement consisting of the Initial Term and includes any subsequent renewals and extensions provided for in this Agreement.</dd>

        <dt>User</dt>
        <dd>has the meaning ascribed to that term in subsection 6(a) of this Agreement.</dd>
      </dl>

      <h4>2.	Services.</h4>
      <p>
        (a)	Provision of the Services. Conditional on Customer: (i) using commercially reasonable efforts to cooperate with the reasonable requests of Realvision; (ii) complying with the technical requirements and Realvision guidelines for initiating the performance of Services, including but not limited to creating image files, uploading image files, and submitting appointment requests, as may be provided in the Documentation or Platform and (iii) providing Realvision with access to Customer Data and any required third party data (and obtaining all required third party consents in respect of such access). Realvision will use commercially reasonable efforts to provide the Services to Customer on the terms and conditions set out in this Agreement. Customer shall not use the Service for any purposes other than those described in this Agreement.
      </p>
      <p>
        (b)	Provisioning of the Platform.  Realvision will use commercially reasonable efforts to: make the Platform available 24 hours a day, 7 days a week, with minimal downtime; except for: (1) planned downtime for maintenance and scheduled upgrades (which may alter the functionality of the Platform); and (2) unavailability caused by circumstances beyond Realvision’s reasonable control, including acts of God, acts of government, flood, fire, earthquakes, civil unrest, acts of terror, strikes or other labour problems, Internet service provider failures or delays, or the unavailability of any third-party provided services.
      </p>
      <p>
        (c)	No Responsibility for Data Storage and Backup. Realvision does not guarantee that the Platform will provide backups of any Customer Data stored on it.  It is Customer’s responsibility to backup onto Customer’s own local system all Customer Data, including all data, files and records that Customer submits to Realvision.
      </p>
      <h4>3.	License Grants.</h4>
      <p>
        (a)	License Grants by Realvision.  Subject to the terms and conditions of this Agreement and Customer’s compliance therewith, Realvision grants to Customer: (i) a revocable, non-exclusive, non-transferable license during the Term to access and use the Platform over the Internet in connection with receiving the Services in accordance with this Agreement; and,  (ii) a limited, revocable, perpetual, non-exclusive, non-transferrable, royalty-free, fully paid-up worldwide license to use, and access any Deliverables that are provided by Realvision to Customer pursuant to this Agreement, in each case solely for Customer’s business purposes.  License to the Deliverables extends only to Customer right to embed link to the Deliverables in Customer website, not to download or otherwise replicate Deliverables.
      </p>
      <p>
      (b)	License Revocation by Realvision.  Realvision reserves the right to revoke the license to access and use the Deliverables and the Platform for any Customer breach of any term of the Agreement, including, but not limited to:
      </p>
      <ul>
      <li>
        (i)	Failure to Pay Subscription Fees.  If Customer fails to pay Subscription Fees on the date such fees become due, and the Customer has not notified Realvision of an intention to terminate the Agreement in accordance of the Termination conditions set forth in this Agreement, Realvision may revoke Customer license to any and all Deliverables already produced by Realvision and delivered to Customer, and may revoke Customer access to the Platform;
      </li>
      <li>
        (ii)	Copying or Reproducing Deliverables.  Realvision retains the right to revoke Customer license to Deliverables if Customer downloads or copies and stores any Deliverables, or , unless specifically permitted as a function of the Platform, or with prior written consent by Realvision
      </li>
      </ul>
      <p>
        (c)	License Grant by Customer. Customer grants to Realvision a transferrable, royalty-free, fully paid, worldwide license to copy, use, reproduce, modify, develop, access, collect, store and use and otherwise exploit the Customer Data.
      </p>

      <h4>4.	Reservation of Rights.</h4>
      <p>
        (a)	Rights Reserved by Realvision.  Realvision expressly reserves all rights in the Services, Platform, Deliverables, and all other materials provided by Realvision (“Realvision Property”) that are not specifically granted or licensed to Customer pursuant to the Terms of this Agreement. All right, title and interest in the Realvision Property including the Deliverables, as well as any update, modification, adaptation, translation, customization of Deliverables thereof and all intellectual property rights therein will remain with Realvision (or Realvision’s third party suppliers, as applicable). Realvision Property is licensed on a subscription basis on the terms and conditions of this Agreement and not “sold” to Customer.
      </p>
      <p>
        (b)	Rights Reserved by Customer.  Customer expressly reserves all rights in Customer Data uploaded or transmitted to the Platform, subject to the license that Customer grants Realvision in accordance with the provisions of this Agreement, and provided that Customer does not acquire any intellectual property rights in the Services, the Platform or any elements of any of the foregoing.
      </p>
      <h4>5.	Privacy Policy; Internet Security Disclaimer; Limitation, Suspension or Termination of Access.</h4>
      <p>
        (a)	Privacy Policy. To the extent that Customer Data may contain any Personal Information, Customer agrees to the terms of (on Customer’s behalf and on behalf of each User) to Realvision’s use, collection, storage and disclosure of such Personal Information for the purposes authorized under this Agreement and in accordance with Realvision’s privacy policy (the “Privacy Policy”) located at: http://real.vision/legal/privacy, and which is herein incorporated by reference and forms part of this Agreement.
      </p>
      <p>
        (b)	Internet Security Disclaimer. As between Realvision and Customer, Customer is solely responsible for the accuracy, quality, integrity, legality, reliability, and appropriateness of all Customer Data. Customer understands that the technical processing and transmission of  Customer Data is fundamentally necessary to use of the Services. Therefore, Customer expressly consents to Realvision’s storage of Customer Data, which will involve transmission over the Internet, and over various networks, only part of which may be owned and/or operated by Realvision. Customer acknowledges and understands that Customer Data may be accessed by unauthorized persons when communicated across the Internet, network communications facilities, telephone or other electronic means. Realvision is not responsible for any Customer Data which is delayed, lost, altered, intercepted or stored during the transmission of any data whatsoever across public networks not owned or operated by Realvision, including, the Internet, third party websites, and your local network. Customer agrees that Realvision is not in any way responsible for any interference with Customer’s use of or access to the Services or security breaches arising from or attributable to the Internet and Customer waives any and all claims against Realvision in connection therewith.
      </p>
      <p>
        (c)	Limitation, Suspension or Termination of Access. In addition to any other suspension or termination rights of Realvision pursuant to this Agreement, certain extraordinary circumstances may require Realvision may suspend, terminate or limit Customer’s access to or use of the Platform or the Services, or any component thereof, without notice in order to: (a) prevent damage to, or degradation of the integrity of, Realvision’s systems or Realvision Property; (b) comply with any law, regulation, court order, or other governmental request or order; or (c) otherwise protect Realvision from potential legal liability or harm to its reputation or business. Realvision will use commercially reasonable efforts to notify Customer of the reasons for such limitation, suspension or termination action as soon as reasonably practicable. In the event of a limitation or suspension, Realvision will promptly restore Customer’s access to the Offering as soon as the event giving rise to the limitation or suspension has been resolved, as determined in Realvision’s sole discretion. Nothing contained in this Agreement will be construed so as to limit Realvision’s rights in any way with respect to any of the foregoing activities. Realvision will not be responsible for any loss or damages incurred by Customer as a result of any limitation, termination or suspension of access to or use of the Platform or the Services under this Section.
      </p>
      <h4>6.	Customer Responsibilities and Restrictions.</h4>
      <p>
        (a)	Customer ID. Customer is liable for all activity occurring on Customer account, either by Customer or Customer’s employees, agents, representatives, contractors and subcontractors, including any and all Processing Fees (defined in Section 9(b), below)  and/or other expenses that are incurred through use of the Platform and Services. Upon Customer’s request, but subject to any limitations associated with Customer’s enterprise subscription account, Realvision will issue user identification and password (“User ID”) to Customer for each individual Customer wishes to have access to and use of the Offering (each, a “User”). Customer may only identify its employees and contractors who, in each case, are bound by confidentiality restrictions at least as restrictive as this Agreement, as Users.  Users may only access and use the Platform and the Services through their particular User ID. Customer will not allow Users to share their User ID with any other person. Customer is responsible for maintaining the confidentiality of User ID and will promptly notify Realvision of any actual or suspected unauthorized use of the Offering.  Realvision reserves the right to replace any User ID if it determines it may have been used for an unauthorized purpose. User IDs can be revoked upon five (5) days’ written notice to Realvision by contacting Realvision at info@real.vision.
      </p>
      <p>
        (b)	Customer Responsibilities and Restrictions. Customer agrees that Customer is responsible for all activity occurring under the User IDs and for the compliance by the Users with this Agreement.  Without limiting the generality of the foregoing, Customer agrees that Customer will not, and will not permit any person to:
      </p>
      <ul>
        <li>
          (i)	use the Offering other than as permitted by this Agreement;
        </li>
        <li>
          (ii)	use the Offering to send, store, publish, post, upload or otherwise transmit any Customer Data in violation of any warranty, representation or obligation of Customer under this Agreement;
        </li>
        <li>
          (iii)	license, sublicense, sell, resell, rent, lease, transfer, assign, distribute, time share or otherwise commercially exploit or make the Offering available to any third party, other than to the Users, or as otherwise expressly contemplated in accordance with this Agreement;
        </li>
        <li>
          (iv)	use the Offering to upload, collect, transmit, store, use or process, or ask Realvision to obtain from third parties, any Customer Data: 
          <ul>
            <li>
              (A) that Customer does not have the lawful right to copy, transmit, distribute, and display (including any Customer Data that would violate any confidentiality or fiduciary obligations that Customer might have with respect to the Customer Data);
            </li>
            <li>
              (B) for which Customer does not have the consent or permission from the owner of any Personal Information contained therein;
            </li>
            <li>
              (C) that infringes, misappropriates or otherwise violates any intellectual property or other proprietary rights of any third party (including any copyright, trademark, patent, trade secret, or other intellectual property right, or moral right or right of publicity or privacy);
            </li>
            <li>
              (D) that is tortious, defamatory, obscene, or offensive; or
            </li>
            <li>
              (E) that violates, or encourages any conduct that would violate, any applicable law or regulation or would give rise to civil or criminal liability.
            </li>
          </ul>
        </li>
        <li>
          (v)	use the Offering to send, store, publish, post, upload or otherwise transmit any viruses, Trojan horses, worms, time bombs, corrupted files or other computer programming routines that are intended to damage, detrimentally interfere with, surreptitiously intercept or expropriate any systems, data, personal information or property of another;
        </li>
        <li>
          (vi)	continue to use the Offering in a manner that interferes with or disrupts the integrity or performance of the Offering following a notice from Realvision of such use;
        </li>
        <li>
          (vii)	attempt to gain unauthorized access to the Offering or its related systems or networks;
        </li>
        <li>
          (viii)	Remove or attempt to remove, cover or attempt to cover any Realvision logo, branding, or trademark from the Deliverables or remove, cover or attempt to remove or cover any Customer logo, branding, trademark or agent information from the Deliverables;
        </li>
        <li>
          (ix)	Modify the Deliverables in any way without prior written consent from Realvision;
        </li>
        <li>
          (x)	use or knowingly permit the use of any security testing tools in order to probe, scan or attempt to penetrate or ascertain the security of the Offering;
        </li>
        <li>
          (xi)	use any data mining, robots or similar data gathering or extraction methods;
        </li>
        <li>
          (xii)	access the Offering for the purpose of building a similar or competitive product or service or for the purpose of obtaining unauthorized access to the Offering;
        </li>
        <li>
          (xiii)	copy, translate, create a derivative work of, reverse engineer, reverse assemble, disassemble, or decompile the Offering or any part thereof or otherwise attempt to discover any source code or modify the Offering, except as expressly provided for in this Agreement.
        </li>
      </ul>
      <p>
        Realvision reserves the right to terminate, limit, or suspend Customer access to the Offering, including access to all Deliverables hosted on the Realvision services, for any Customer or User breach of these restrictions or any other violation of any other term of this Agreement.
      </p>
      <p>
        (c)	Customer Responsibility for Customer Data. Customer has sole responsibility for obtaining all third party consents and making all third party disclosures in accordance with applicable laws regarding Customer Data. Realvision will use the Customer Data it is provided by Customer or third parties in performing the Services “as is”, and is not responsible for reviewing, validating or otherwise confirming the accuracy, appropriateness or completeness of Customer Data.
      </p>
      <h4>7.	Term.</h4>
      <p>
        This Agreement will commence on the date it is entered into by Customer (“Effective Date”) and continue for an initial term (“Initial Term”) of duration as indicated on the invoice for your Initial Term, and, on expiry of the Initial Term, will continue to automatically renew for further periods as indicated on the invoice for your Initial Term unless or until terminated by either Party in accordance with the Termination procedures in section 14 below.
      </p> 

      <h4>8.	Documentation.</h4>
      <p>
        Realvision at its sole discretion will provide to Customer, Documentation sufficient to instruct Customers on the technical requirements for acquiring and transmitting Customer Data, including images files, in connection with the Service.   Documentation and technical requirements for the Offering may change from time to time at Realvision’s sole discretion, and Realvision will make commercially reasonable efforts to inform all Customers active at the time of the change. It is Customer’s sole responsibility to ensure it has the ability to provide Customer Data, including image files, in accordance to the newest technical requirements.
      </p>
      <h4>9.	Fees and Payment.</h4>
      <p>
        (a) Subscription Fee.  Subscription Fees due and payable in full by Customer at the start of the Initial Term in advance of receipt of the Services, and automatically charged to Customer credit card on a monthly basis for each subsequent renewal Term until Subscription is terminated in accordance with the terms of this Agreement.  Subscription Fees are determined in Realvision’s sole discretion and are subject to change at any time and for any reason.  In the event of a change to Subscription Fees, Realvision will provide you with written notice of such changes.  Should your Subscription Fee change partway through any Term of this Agreement, the new Subscription Fee will take effect starting at the next Term.
      </p>
      <p>
        (b)	Processing Fees.  In addition to the monthly Subscription Fees, Realvision charges a processing fee for the performance of a Services you request via the Platform, and in the case of submitted Customer Data, apply regardless of whether the submitted Customer Data completes, or the submitted Customer Data meets the Realvision specifications and can be rendered into a usable Deliverable.  Processing Fees are payable on a monthly basis.  At the end of each month we will determine the Services performed in excess of limits allowed by your Subscription Fees, and send an invoice accordingly. We will automatically charge the amount to the credit card we have on file (see subsection 9(c) below) as early as immediately upon the end of each monthly billing period.  The current rate of your Processing Fees can be found when you log in to your Account.  The  rate for the Processing Fees is determined by Realvision in its sole discretion, and may change at any time for any reason, upon provision of five (5) business days’ written notice to you, which notice will be sent to the email address you have provided us with.  If you do not agree with any change to the Processing Fees your sole recourse is to cease uploading Customer Data to the Platform and/or terminate your account in accordance with the Termination provisions set forth in Section 14, below. 
      </p>
      <p>
        (c)	Payment Method.  All payments must be made in advance by credit card on the Website.  Unless by prior written approval from Realvision, Realvision does not accept cash, cheque, money order, or any other method of payment other than credit card.
      </p>
      <p>
        (d)	Late Payment Penalties.  If Customer fails to make any payment in full when it becomes due, Realvision may charge interest on overdue Subscription Fees in the amount of 19.5% annually (1.5% per month). In addition, Realvision retains the right to suspend, limit or terminate Customer access to the Deliverables until any and all overdue amounts are paid.
      </p>
      <p>
        (e)	Certain Taxes.  Fees and charges quoted in this Agreement do not include, and Customer shall pay, indemnify and hold Realvision harmless, from all sales, use, gross receipts, value-added, GST/HST, personal property or other taxes, and all applicable duties, tariffs, assessments, export and import fees or similar charges (including interest and penalties imposed thereon) on the transactions contemplated in connection with this Agreement, other than taxes based on the net income or profits of Realvision
      </p>
      <h4>10.	Confidential &amp; Proprietary Information.</h4>
      <p>
        Definitions.  For purposes of this section, a Party receiving Confidential &amp; Proprietary Information (as defined below) will be the “Recipient” and the Party disclosing such information will be the “Discloser”, and “Confidential & Proprietary Information” includes all information disclosed by Discloser to Recipient during the Term of this Agreement and marked as “confidential” or “proprietary” or which a reasonable person would understand to be confidential or proprietary; provided that all parts of the Platform and the Services (other than any Customer Data), whether marked as “confidential” or “proprietary” or not, will be considered to be Realvision Confidential & Proprietary Information; and further provided that Discloser’s Confidential & Proprietary Information does not include: (i) information already known or independently developed by Recipient outside the scope of this relationship by personnel not having access to any Discloser’s Confidential & Proprietary Information; (ii) information that is publicly available through no wrongful act of Recipient, or (iii) information received by Recipient from a third party who was free to disclose it without confidentiality obligations.
      </p>
      <ul>
        <li>
          (a)	Covenant.  To the extent that any Confidential and Proprietary Information will be shared between the Parties to this Agreement, Recipient hereby agrees that during the Term and at all times thereafter it shall not (i) disclose such Confidential & Proprietary Information of the Discloser to any person or entity, except to its own personnel, affiliates or contractors having a “need to know”, and to such other recipients as the Discloser may approve in writing; or (ii) use Confidential & Proprietary Information of the Discloser except to exercise its license rights or perform its obligations under this Agreement. Recipient shall use at least the same degree of care in safeguarding the Confidential & Proprietary Information of the Discloser as it uses in safeguarding its own confidential information of a similar nature, but in no event shall less than reasonable care be exercised. Upon the earlier of: (A) Discloser’s written  request; and (B) the termination or expiration of this Agreement, regardless of whether a dispute may exist, Recipient shall return or destroy (as instructed by Discloser) all Confidential & Proprietary Information of Discloser in its possession or control and cease all further use thereof. Realvision may retain a copy of such Confidential & Proprietary Information for the sole purpose of and to the extent necessary for it to comply with applicable and legal, regulatory, or reasonable internal back-up or archival policies and requirements.  Notwithstanding the foregoing, Recipient may disclose Discloser’s Confidential & Proprietary Information to the extent that such disclosure is necessary for the Recipient to enforce its rights under this Agreement or is required by law or by the order of a court or similar judicial or administrative body, provided that the Recipient promptly notifies the Discloser in writing of such required disclosure and cooperates with the Discloser to seek an appropriate protective order.
        </li>
        <li>
          (b)	Injunctive Relief.  Recipient acknowledges that violation of the provisions of this section may cause irreparable harm to Discloser not adequately compensable by monetary damages. In addition to other relief, it is agreed that injunctive relief shall be available without necessity of posting bond to prevent any actual or threatened violation of such provisions.
        </li>
      </ul>
      <h4>11.	Customer Warranty; Disclaimer; Indemnity.</h4>
      <p>
        (a)	Customer Warranty.  Customer represents and warrants to, and covenants with Realvision that the Customer Data will contain no personally identifiable information or information that is subject to privacy laws (“Personal Information”), except for Personal Information: (i) in respect of which Customer has provided all disclosures and obtained all consents from all applicable individuals in accordance with applicable laws; and (ii) that complies with Customer’s representations, warranties and obligations set out in this Agreement.
      </p>
      <p>
        (b)	Warranty Disclaimer. EXCEPT AS SPECIFICALLY PROVIDED IN THIS SECTION, THE OFFERING AND ANY OTHER PRODUCTS AND SERVICES PROVIDED BY REALVISION TO CUSTOMER ARE PROVIDED “AS IS”, “AS AVAILABLE”, WITH ALL FAULTS AND WITHOUT ANY WARRANTIES, REPRESENTATIONS OR CONDITIONS OF ANY KIND. REALVISION HEREBY DISCLAIMS ALL EXPRESS, IMPLIED, COLLATERAL OR STATUTORY WARRANTIES, REPRESENTATIONS OR CONDITIONS, WHETHER WRITTEN OR ORAL, INCLUDING ANY IMPLIED REPRESENTATIONS OR WARRANTIES OR CONDITIONS OF MERCHANTABILITY,  MERCHANTABLE QUALITY, TITLE, NONINFRINGEMENT, SECURITY, RELIABILITY, COMPLETENESS, QUIET ENJOYMENT, ACCURACY, QUALITY, INTEGRATION OR FITNESS FOR A PARTICULAR PURPOSE.  REALVISION DOES NOT WARRANT THAT THE OFFERING WILL OPERATE WITHOUT INTERRUPTION OR BE ERROR FREE.  WITHOUT LIMITING THE GENERALITY OF ANY OF THE FOREGOING, REALVISION EXPRESSLY DISCLAIMS ANY REPRESENTATION OR WARRANTY OR CONDITION THAT ANY DATA OR INFORMATION PROVIDED TO CUSTOMER IN CONNECTION WITH CUSTOMER’S USE OF THE SERVICES IS ACCURATE, OR CAN OR SHOULD BE RELIED UPON BY CUSTOMER FOR ANY PURPOSE WHATSOEVER.
      </p>
      <p>
        (c)	Indemnity. Customer agrees to indemnify and hold harmless REALVISION, its employees, officers, directors and affiliates against any and all liability (including damages, recoveries, deficiencies, interest, penalties and reasonable attorney’s fees) to third parties relating to: (i) Customer Data, (ii) Customer’s breach of any of Customer’s obligations, representations or warranties under this Agreement; or (iii) Customer’s use of the Offering, including in combination with any third party software, application or service.
      </p>
      <h4>12.	Limitation of Liabilities.</h4>
      <p>
        The Parties acknowledge that the following provisions have been negotiated by them and reflect a fair allocation of risk and form an essential basis of the bargain and shall survive and continue in full force and effect despite any failure of consideration or of an exclusive remedy:
      </p>
      <ul>
        <li>
          (a)	Amount.  IN NO EVENT WILL THE TOTAL AGGREGATE LIABILITY OF REALVISION IN CONNECTION WITH OR UNDER THIS AGREEMENT EXCEED THE TOTAL AMOUNT OF FEES RECEIVED BY REALVISION UNDER THIS AGREEMENT DURING THE 2 MONTHS PRECEDING THE FIRST CLAIM MADE BY CUSTOMER OR ITS CONTRACTORS, REPRESENTATIVES, EMPLOYEES, OR ANY OTHER AFFILIATE OR AGENTS OF CUSTOMER. FOR GREATER CERTAINTY, THE EXISTENCE OF ONE OR MORE CLAIMS UNDER THIS AGREEMENT WILL NOT INCREASE THIS MAXIMUM LIABILITY AMOUNT.  IN NO EVENT SHALL REALVISION’S SUPPLIERS HAVE ANY LIABILITY ARISING OUT OF OR IN ANY WAY CONNECTED TO THIS AGREEMENT.
        </li>
        <li>
          (b)	Type.  IN NO EVENT SHALL REALVISION BE LIABLE TO CUSTOMER FOR ANY (I) SPECIAL, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES, (II) LOST SAVINGS, PROFIT, DATA, USE, OR GOODWILL, (III) BUSINESS INTERRUPTION, EVEN IF NOTIFIED IN ADVANCE OF SUCH POSSIBILITY, OR (IV) PERSONAL OR PROPERTY DAMAGE ARISING OUT OF OR IN ANY WAY CONNECTED TO THIS AGREEMENT, REGARDLESS OF CAUSE OF ACTION OR THE THEORY OF LIABILITY, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE, GROSS NEGLIGENCE, FUNDAMENTAL BREACH, BREACH OF A FUNDAMENTAL TERM) OR OTHERWISE.  IN NO EVENT SHALL REALVISION BE LIABLE FOR PROCUREMENT OR COSTS OF SUBSTITUTE PRODUCTS OR SERVICES.
        </li>
      </ul>
      <h4>13.	Notices.</h4>
      <p>
        Notices sent to either Party shall be effective when delivered in person, by mail or email (as applicable): one (1) day after being sent by overnight courier, two (2) days after being sent by first class mail postage prepaid to the official contact designated below, and (3) immediately after being received by the other party’s email server.  Notices must be in writing and delivered to the respective email or postal address  provided by Customer to Realvision,  and in the case of Realvision, by email only at info@real.vision. Customer may change its contact information by giving notice of such change to Realvision.
      </p>
      <h4>14.	Termination.</h4>
      <ul>
        <li>
          (a)	On Notice. Realvision may terminate this agreement at any time without notice if any Customer or User violates any of the User Restrictions listed in subsection 6(b) of this Agreement.  Additionally, Realvision may terminate this Agreement at any time for any reason by providing Customer with 30 days’ notice of termination. Customer may terminate this Agreement at any time and without termination fees, penalties or additional charges by providing Realvision with 30 days’ notice of termination, or by indicating to Realvision that Customer does not wish to renew the Subscription, in which case, the Customer will have access to the Services for the remainder of the then-current Term, after which point Customer access to the Services will terminate.  Upon termination of this agreement, Subscription Fees already paid are not refundable, but may be credited towards Processing Fees relating to future Services at Realvision's sole discretion.
        </li>
        <li>
          (b)	Generally.  Either Party may, in addition to other relief, suspend or terminate this Agreement if the other Party breaches any material provision hereof, and fails within fifteen (15) days after receipt of notice of such breach to correct such material breach or to commence corrective action reasonably acceptable to the aggrieved Party and proceed with due diligence to completion, in each case unless the material breach is incapable of being cured.  Either Party shall be in default hereof if it becomes insolvent, makes an assignment for the benefit of its creditors, a receiver is appointed, or a petition in bankruptcy is filed with respect to the Party and is not dismissed within thirty (30) days.

        </li>
        <li>
          (c)	Effect of Termination. In the event of Termination by either Party,  Realvision has the right to delete all Customer Data or any other information in the Customer account, and may also restrict or terminate Customer access to the Deliverables

        </li>
        <li>
          (d)	Survival.  Upon termination or expiration of this Agreement for any reason: (a) all rights and obligations of both Parties (except for Customer’s payment of all sums then owing), including all licenses granted hereunder, shall immediately terminate except as provided below; (b) within thirty (30) days after the effective date of termination, each Party shall comply with the obligations to return or destroy all Confidential Information of the other Party, as set forth Section 10 (Confidential & Proprietary Information).  The following Sections will survive expiration or termination of this Agreement for any reason:  Section 4 (Reservation of Rights), Section 6 (Customer Responsibilities and Restrictions), Section 10 (Confidential & Proprietary Information), Section 11 (Customer Warranty; Disclaimer; Indemnity), Section 12 (Limitation of Liabilities), Section 14(d) (Survival), and Section 15 (General Provisions).
        </li>
      </ul>
      <h4>15.	General Provisions.</h4>
      <ul>
        <li>
          (a)	Assignment.  Customer may not assign this Agreement to any third party without Realvision’s prior written consent, except this Agreement may be assigned by Customer to any third party that controls, is controlled by, or is under common control with Customer.  Realvision may assign this Agreement or any rights hereunder to any third party, but shall provide Customer with written notice of any such assignment.  Any assignment in violation of this Section shall be void. The terms of this Agreement shall be binding upon and inure to the benefit of the Parties’ successors and permitted assignees.
        </li>
        <li>
          (b)	Choice of Law.  This Agreement and any action related thereto shall be governed by and construed in accordance with the laws of the Province of Ontario and the federal laws of Canada applicable therein, without regard to conflicts of law principles.  The Parties hereby irrevocably attorn to the exclusive personal jurisdiction and venue of the courts sitting in Toronto, Ontario.
        </li>
        <li>
          Publicity. Customer agrees that Realvision may refer to Customer in Realvision’s list of customers and may use Customer’s name and logo for marketing-related purposes. Use of Customer’s name in any other marketing materials or press announcements will be submitted to Customer in advance for approval, provided that such approval will not be unreasonably withheld.  Customer shall not modify, amend, or otherwise change Realvision’s branding as it appears on the Deliverables, or use Realvision’s branding in any way other than it appears in the Deliverables, without Realvision’s prior written consent.
        </li>
        <li>
          (c)	Feedback.  If You provide Realvision with any suggestions, comments or other feedback relating to any aspect of the Offerings ("Feedback"), Realvision may use such Feedback in the Website or in any other Realvision products or services. Accordingly, You agree that: (a) Realvision is not subject to any confidentiality obligations in respect to the Feedback, (b) the Feedback is not confidential or proprietary information of You or any third party and You have all of the necessary rights to disclose the Feedback to Realvision, (c) Realvision (including all of its successors and assigns and any successors and assigns of any of the Realvision Offerings) may freely use, reproduce, publicize, license, distribute, and otherwise commercialize Feedback in any Realvision Offerings, and (d) You are not entitled to receive any compensation or re-imbursement of any kind from Realvision or any of the other users of the Website in respect of the Feedback.
        </li>
        <li>
          (d)	Construction.  Except as otherwise provided herein, the Parties rights and remedies under this Agreement are cumulative.  The term “including” means “including without limitation.” The headings of sections of this Agreement are for reference purposes only and have no substantive effect.  The terms “consent” or “discretion”, when used in respect of Realvision to withhold such consent or exercise such discretion (as applicable) arbitrarily and without any implied obligation to act reasonably or explain its decision to Customer.
        </li>
        <li>
          (e)	Force Majeure.  Neither Party shall be liable for delays caused by events beyond its reasonable control, except non-payment of amounts due hereunder shall not be excused by this provision.
        </li>
        <li>
          (f)	Severable.  Any provision hereof found by a tribunal of competent jurisdiction to be illegal or unenforceable shall be automatically conformed to the minimum requirements of law and all other provisions shall remain in full force and effect.
        </li>
        <li>
          (g)	Waiver.  The failure of a party to claim a breach of any term of this Agreement shall not constitute a waiver of such breach or the waiver of the right of such party to enforce any subsequent breach of any term of this Agreement.
        </li>
        <li>
          (h)	Independent Contractors.  Customer’s relationship to Realvision is that of an independent contractor, and neither Party is an agent or partner of the other.  Customer will not have, and will not represent to any third party that it has, any authority to act on behalf of Realvision.
        </li>
        <li>
          (i)	Entire Agreement.  This Agreement constitutes the entire agreement between the Parties with respect to the subject matter hereof and supersedes all other communications, including all other executed Agreements, whether written or oral.  This Agreement may be executed in one or more counterparts, each of which shall be deemed an original and all of which shall be taken together and deemed to be one instrument.
        </li>
        <li>
          (j)	English Language. It is the express wish of the parties that this Agreement and all related documents be drawn up in English.  C’est la volonté expresse des parties que la présente convention ainsi que les documents qui s’y rattachent soient rédigés en anglais.
        </li>
      </ul>

      <p>
        Last Updated: March 1, 2022
      </p>
    </LegalPage>
  )
}